<template>
  <PageContainer>
    <div class="organization-item-container">
      <v-progress-linear v-if="isLoading" indeterminate />
      <OrganizationInfo :item="organization" @edit="edit" @viewSchedule="viewSchedule" />
    </div>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import PageContainer from "@/components/uikit/PageContainer.vue";
import { useOrganizationStore } from "@/pinia-store/organization";
import { routesEnum } from "@/types/Routes.enum";
import OrganizationInfo from "@/views/Owner/Organizations/OrganizationInfo.vue";

export default {
  name: "OrganizationIndex",
  data() {
    return {
      organizationId: null,
    };
  },
  components: { OrganizationInfo, PageContainer },
  computed: {
    ...mapState(useOrganizationStore, ["organizations", "isLoading", "organization"]),
  },
  methods: {
    ...mapActions(useOrganizationStore, ["getOrganizations", "setForm", "setOrganization"]),
    add() {
      this.setForm({});

      this.$router.push({ name: routesEnum.addOrganization });
    },
    edit(item) {
      this.setForm(item);
      this.$router.push({
        name: routesEnum.addOrganization,
        params: { id: item.id },
      });
    },

    viewSchedule(item) {
      this.setOrganization(item);
      this.$router.push({
        name: routesEnum.organizationSchedule,
        params: { organizationId: item.id },
      });
    },
  },
  beforeMount() {
    this.organizationId = this.$route.params.organizationId;
  },
  async mounted() {
    await this.getOrganizations();
    console.log("Organization mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-item-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
</style>
